<template>
  <div class="position-relative h-100" ref="editor-wrapper">
    
    <div v-if="currentTheme != 'monaco' "
      ref="codemirror"
      class="codemirror-component" 
      @keydown.ctrl.s="saveShortcut"
    />
    <monaco
      v-else
      language="javascript"
      :height="heightByJS"
      :key="'js-monaco-editor' + heightByJS"
      @MonacoCtrlS="saveShortcut"
      v-model="vModel"
    />

    <div class="editor-config-container d-flex align-items-start justify-content-end">
      
      <b-collapse :visible="showingConfigs">
        <div class="editor-configs">
          
          <div align="center" class="font-weight-bolder border-bottom">
            Editor Settings  
          </div>
          
          <div class="mt-50 mb-1">
            
            <div>
              <div class="custom-label">
                Theme:
              </div>
              <b-form-select size="sm" @change="changeTheme" :value="currentTheme">
 
                <b-form-select-option :value="theme.value" v-for="theme in themeOptions" :key="'select-item-'+theme.value">
                  
                  <span v-if="theme.label">
                    {{theme.label}}
                  </span>
                  <span v-else>
                    {{ theme.value }}
                  </span>
                </b-form-select-option>

              </b-form-select>
            </div>

          </div>
          <div v-if="currentTheme != 'monaco'">
            <b-button variant="outline-dark" class="mb-50 py-25 px-50 font-weight-bolder" @click="formatDocument()">
              <b-icon icon="text-left"/>
               Format Code
            </b-button>
            <span class="small text-secondary">
              Ctrl + Alt + F
            </span>
            <div class="d-flex small align-items-center">
              <!-- <b-form-checkbox id="autoformat-on-save-js" class="p-relative"/>
              <label for="autoformat-on-save-js">
                Automatically format on Save
              </label> -->
                <b-form-checkbox size="" v-model="autoFormat" @change="storeAutoFormat" :id="'autoformat-on-save-js' + uuid" />
                <label :for="'autoformat-on-save-js' + uuid" class="mb-0">
                  Automatically format code when shown
                </label>
            </div>
          </div>

        </div>
      </b-collapse>
      
      <b-button variant="none" @click="showingConfigs = !showingConfigs">
        <b-iconstack>
          <b-icon icon="gear-fill" variant="light" scale="1.2" stacked/>
          <b-icon icon="gear-fill" variant="dark" stacked/>
        </b-iconstack>

      </b-button>
    </div>
  </div>
</template>

<script>
import monaco from "@/layouts/components/editor/monaco.vue";
import {EditorView , keymap , tooltips} from "@codemirror/view";
import {basicSetup} from "codemirror";
import {standardKeymap, insertTab, indentLess} from "@codemirror/commands";
import {CompletionContext} from "@codemirror/autocomplete"
import CodeMirrorThemes from "@/custom/class/Enum/CodeMirrorThemes.js"

import {javascript , javascriptLanguage, esLint} from "@codemirror/lang-javascript";


import prettier from 'prettier-standalone'
import {linter} from "@codemirror/lint";

import {EditorState, Compartment} from "@codemirror/state"
import * as broserifyLinter from "eslint-linter-browserify";

import {
	HighlightStyle,
	syntaxHighlighting,
  syntaxTree,
} from '@codemirror/language';

import {
  BButton,
  BIcon,
  BCollapse,
  BFormSelect,
  BFormSelectOption,
  BIconstack,
  BFormCheckbox,
} from 'bootstrap-vue'
import Sources from "@/custom/class/Enum/Sources.js";
import { v4 as uuidv4 } from "uuid";


export default {
  components: {
    BButton,
    BIcon,
    BCollapse,
    BFormSelect,
    BFormSelectOption,
    BIconstack,
    BFormCheckbox,
    monaco,
  },
	data(){
		return{
      codeMirrorView: undefined,
      autoFormat: false,
      showingConfigs: false,
      themeCompartment: undefined,
      heightByJS: "",
      currentTheme: '',
      uuid: uuidv4()
		}
	},
	props: {
		height: {
			type: String,
			default: "300px",
		},
		language: {
			type: String,
			required: true
		},
		value: {
			type: String,
			default: "",
		},
		readOnly:{
			type: Boolean,
			default: false,
		}
	},
	beforeDestroy () {
		this.$emit('destroyed');
	},
	mounted() {
    
    if (this.$refs['editor-wrapper']){
      new ResizeObserver(()=>{
        this.heightByJS = this.$refs['editor-wrapper'].clientHeight + 'px'
      }).observe(this.$refs['editor-wrapper']);
    }


    this.init();
	},
	computed: {
		vModel: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
    themeOptions(){
      let r = []
      let themeMap = new CodeMirrorThemes().items
      Object.keys(themeMap).forEach((theme)=>{
        let t = {
          dark: themeMap[theme].variant == 'dark',
          value: theme,
          label: themeMap[theme].label || undefined
        }
        r.push(t)
      })
      const monaco = {
        dark: true,
        value: 'monaco',
        label: 'monaco (legacy editor)'
      }
      return [monaco,...r]
    },
    sourceRecommendations(){
      let items = new Sources().items
      let res = []
      items.forEach((itm)=>{
        if (itm.type != 'middleware'){
          if (itm.fields.length > 0){
            itm.fields.forEach((field)=>{
              let t = {label: `$.${itm.key}.${field}`, type: "variable"}
              res.push(t)
            })
          } else {
            let t = {label: `$.${itm.key}`, type: "variable"}
            res.push(t)
          }
        }
      })
      return res
    },
	},
	methods: {
    async init(){
      if (localStorage.getItem('autoFormatJsEditor')){
        this.autoFormat = true
      } else {
        this.autoFormat = false
      }

      let dom = this.$refs['codemirror']

      this.currentTheme = 'ambience'
      if (localStorage.getItem('userEditorTheme')){
        this.currentTheme = localStorage.getItem('userEditorTheme')
      }

      if (this.currentTheme == 'monaco'){
        return
      }
      let defaultTheme = this.makeTheme(this.currentTheme)

      let customCompletion = javascriptLanguage.data.of({
        autocomplete: this.customAutocompletes
      })

      const updateListenerExtension = EditorView.updateListener.of((update) => {
        if (update.docChanged) {
          this.vModel = update.state.doc.toString()
          this.$emit('editorChange');
        }
      });


      let unusableFunctions = [
        //functions to warn the user that they don't work in this version of EcmaScript
        'require',
        'setTimeout',
        'replaceAll',
        'toLocaleString',
      ]
      unusableFunctions = unusableFunctions.map((el)=>{
        let t = {
          selector: `CallExpression[callee.name='${el}']`,
          message: "Javascript Agent doesn't support this function"
        }
        return t
      })

      const lintConfig = {
          // eslint configuration
          parserOptions: {
            // ecmaVersion: 2019,
            ecmaVersion: 2020,
            //sourceType: "module",
          },
          env: {
            browser: true,
            node: true,
          },
          rules: {
            "no-unused-vars": ["warn", { "vars": "all", "args": "after-usde", "ignoreRestSiblings": false }],
            "no-restricted-syntax": ["error", ...unusableFunctions],
          },
          "extends": ["eslint:recommended",]
      };

      const tabIndend = keymap.of([
        {
          key: 'Tab',
          preventDefault: true,
          run: insertTab,
        },
        {
          key: 'Shift-Tab',
          preventDefault: true,
          run: indentLess,
        },
      ]) 
      
      const docFormat = keymap.of([
        {
          key: 'Ctrl-Alt-f',
          preventDefault: true,
          run: this.formatDocument,
        }
      ])


      const bLinter = new broserifyLinter.Linter()
      const preLinter = {
        verify: function(code,config){

          let startingIdx = 0
          while(true){
            const openIdx = code.indexOf('{{', startingIdx)  
            const closeIdx = code.indexOf('}}', startingIdx)  
          
            if ( (openIdx > -1) && (closeIdx > -1) ){
              startingIdx = Math.min(openIdx, closeIdx) + 1

              if( openIdx >= closeIdx){
                                
              }
              let varBefore = code.substring(openIdx, closeIdx+2)
              let varAfter = varBefore

              varAfter = varAfter.replaceAll('{{', '  ').replaceAll('}}', '  ')
              varAfter = varAfter.replaceAll('@', '_')
              code = code.replaceAll(varBefore, varAfter)
              
            } else {
              break
            }
          }
          
          // code = code.replaceAll('{{','  ') //it's important to substitute to the same amount of characters, to keep indexes consistent
          // code = code.replaceAll('}}','  ') 
          
          let diagnostics = bLinter.verify(code, config)
          
          diagnostics.forEach((d, idx)=>{
            if (d.message == 'Parsing error: Unexpected token {'){
              diagnostics.splice(idx, 1)
            }
          })
          return diagnostics
        }
      }

      // const baseLinter = linter( esLint(new broserifyLinter.Linter(), lintConfig));
      const baseLinter = linter( esLint(preLinter, lintConfig));
        
      let tabSize = new Compartment

      this.themeCompartment = new Compartment
      this.codeMirrorView = new EditorView({
        doc: this.vModel,
        extensions: [
          basicSetup, //basic code editor functionalities
    
          // -- shortcuts -- 
          tabIndend,
          docFormat,
          // ----------------
          tabSize.of(EditorState.tabSize.of(2)),

          javascript(),
          customCompletion, //custom system of autocomplete with $.Sources 
          updateListenerExtension, // updates the vModel every time a change is made to the editor
          this.themeCompartment.of(defaultTheme), //theme style setup
          baseLinter, //esLinter setup, error detection;
          
        ],
        parent: dom,
      })  

      if (this.autoFormat){
        this.$nextTick(()=>{
          this.formatDocument()
        })
      }
    },
    customAutocompletes(context) {
      const txt = (context.state.doc.toString()).slice(0, context.pos)  
      
      let deliniators = '\n\t\' []();:{}=>*%!/+-|&#1"'
      
      let lastIdxs = []
      deliniators.split('').forEach((d)=>{
        lastIdxs.push(txt.lastIndexOf(d))
      })
      const idx = Math.max(...lastIdxs)

      const word = txt.slice(idx+1 , txt.length).toLowerCase()
      
      if (word.length == 0){
        return
      }

      let sources = this.sourceRecommendations;
      
      let startingMatches = []
      let includesMatches = []

      sources.forEach((src)=>{
        let label = src.label.toLowerCase()
        if (label.includes(word)){

          if (label.startsWith(word)){
            startingMatches.push(src)
          } else {
            includesMatches.push(src) 
          }
        }
      })

      const resultedRecommendations = [ ...startingMatches , ...includesMatches]
      const from = context.pos - word.length

      return {
        from: from,
        options: resultedRecommendations
      }
    },
    saveShortcut(e){
      e?.preventDefault();
      this.$emit('MonacoCtrlS');
      this.$emit('CtrlS');
    },		
    makeTheme(theme){
      if (theme == 'monaco'){
        return
      }

      const createTheme = ({variant, settings, styles}) => {
        const theme = EditorView.theme(
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '&': {
              backgroundColor: settings.background,
              color: settings.foreground,
            },
            '.cm-content': {
              caretColor: settings.caret,
            },
            '.cm-cursor, .cm-dropCursor': {
              borderLeftColor: settings.caret,
            },
            '&.cm-focused .cm-selectionBackgroundm .cm-selectionBackground, .cm-content ::selection':
              {
                backgroundColor: settings.selection,
              },
            '.cm-activeLine': {
              backgroundColor: settings.lineHighlight,
            },
            '.cm-gutters': {
              backgroundColor: settings.gutterBackground,
              color: settings.gutterForeground,
            },
            '.cm-activeLineGutter': {
              backgroundColor: settings.lineHighlight,
            },
          },
          {
            dark: variant === 'dark',
          },
        );

        const highlightStyle = HighlightStyle.define(styles);
        const extension = [theme, syntaxHighlighting(highlightStyle)];

        return extension;
      };

     return createTheme(new CodeMirrorThemes().items[theme])
    },
    async changeTheme(newTheme){
      let reInit = false
      if (this.currentTheme == 'monaco'){
        reInit = true
      }

      this.currentTheme = newTheme
      localStorage.setItem('userEditorTheme', newTheme)
      
      if (reInit){
        this.$nextTick(()=>{
          this.init()
        })
        return
      }

      let t = this.makeTheme(newTheme)
      this.codeMirrorView.dispatch({
        effects: this.themeCompartment.reconfigure(t)
      })
    },
    formatDocument(){
      let code = this.vModel
      const beforeFormat = code

      code = code.replaceAll('{{', '__FIQON_1__')
      code = code.replaceAll('}}', '__FIQON_2__')
      code = code.replaceAll('@', '__FIQON_3__')
      try{
        const prettierConfig = {
          useTabs:true, 
          // use_tabs:true, 
          // tabWidth: 4,
          // tab_width: 4,
          // indent_size: 4, 
          
          parser: "babel"
        }

        let formatted = prettier.format(code, prettierConfig)
      
        formatted = formatted.replaceAll('__FIQON_1__', '{{')
        formatted = formatted.replaceAll('__FIQON_2__', '}}')
        formatted = formatted.replaceAll('__FIQON_3__', '@')

        let changes = [ {from: 0, to: beforeFormat.length, insert: formatted} ]
        this.codeMirrorView.dispatch({changes})
      }catch(err){
        console.error(err)
        console.error('error while formatting doc')
      }
    },
    
    storeAutoFormat(a){
      if (a == true){
        localStorage.setItem('autoFormatJsEditor', 'true')
      } else if (a == false) {
        localStorage.removeItem('autoFormatJsEditor')
      }
    }
  },
}
</script>


<style lang="scss">
@import '@/assets/scss/custom-utils.scss';


/*
.codemirror-component{
  //  --- OLD STYLES ---
  --cm-min-height: calc(100vh - 250px);
  .cm-content{min-height: var(--cm-min-height);}
  .cm-gutters { margin: 1px; min-height: var(--cm-min-height); overflow: auto;}

  .cm-scroller { overflow: auto;  max-height: 600px; }
*/

.codemirror-component{
  font-size: 14.5px;
  height: 100%;
  position: relative;

  container-type: inline-size;
  container-name: codemirror;
  @container codemirror (min-width: 0) {
    .cm-content{min-height: 100cqh;}
    .cm-gutters { margin: 1px; min-height: 100cqh; max-height: 100cqh ; height: 100cqh; overflow: auto;}    
    .cm-scroller {overflow: auto; max-height: 100cqh; }
  }

  >.cm-editor{
    min-height: 100%;
    max-height: 100%;
  }  
  .cm-wrap { border: 1px solid silver }

  position: relative !important;
  >*{
    position:relative;
  }

  ::selection{
    background-color: rgba(126, 126, 126, 0.3) !important;
  }

  .cm-lintPoint-error{
    //padding: 10px !important;
    $cm-error: #dd1111;
    border-bottom: 1px dashed $cm-error !important;
    //box-shadow: 0 0 10px #dd1111;
    background-color: transparentize($cm-error, 0.5);
    z-index: -1;

    box-shadow: 1px 0px 0px 0px $cm-error;
    $range: 1px;
    margin-right: -$range;
    padding-left: $range;
    //borber-bottom: 
    &::after{
      margin-left: $range;
      scale: 1.5;
    }
  }
  .cm-lintRange-error{
    //outline: 1px solid red;
    opacity: 0.5;
    //text-decoration-line: underline;
    text-decoration-style: wavy;
    //text-decoration-color: $warning;
  }
}

.custom-label{
  font-size: 13px;
  margin-left: 7px;
  margin-bottom: 0;
  line-height: 13px;
}

</style>

<style lang="scss">
@import '@/assets/scss/custom-utils.scss';

.editor-config-container{
  position: absolute;
  top: 0;  
  right: 0;

  .editor-configs{
    font-size: 15px;
    width: 400px;
    border-radius: 0 0 5px 5px;
    padding: 8px;

    background-color: #20283d;  
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-top: none;
  }

}

</style>