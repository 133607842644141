<template>
<div class="mx-1 position-relative d-flex flex-direction-column h-100">
  <div class="d-flex justify-content-between align-items-end pt-50">
    
    <div>
      <span class="mb-0">{{ $t(`agent.list.${i18nKey}.register_1.title`) }}</span>
      
    </div>

    <div class="d-flex align-items-end mb-25">
      <label class="mr-75 font-weight-bold">
        <b-icon icon="bug" scale="1.2" class="mr-25"/>
        Debug Mode
      </label>
      <b-form-checkbox class="" switch v-model="debugMode"/>
    </div>
  </div>

  <div class="debugger-container">
    <b-collapse :visible="debugMode">
      <div class="debug-container-inner">
        <!-- <p class="text-secondary small font-weight-bold py-50">
          Javascript Debug Mode is defined by a Debug Payload Agent before this Javascript Agent - When this flux is executed the Debug Payload Agent will log the state of $ right before Javascript's execution, allowing for debug function.
          To see this in action run a Test of this Flux.
        </p> -->
        <div align="right">
          <b-button variant="none" class="p-50 w-100 text-dark" @click="debugData.showInitialState = !debugData.showInitialState">
            <span v-if="debugData.showInitialState">
              Hide Initial Sample 
              <b-icon icon="dash" class="float-right"/>
            </span>
            <span v-else>
              Show Initial Sample
              <b-icon icon="plus" class="float-right"/>
            </span>
          </b-button>
        </div>
        <b-collapse class="" :visible="debugData.showInitialState">
          <div class="d-flex justify-content-between align-items-end">
            <label class="font-weight-bolder">State of $ before execution</label>
          </div>
          <json-editor
            class="pb-1 mb-2"
            v-model="debugData.initialState" 
            :modes="['code']" 
            height="300px" 
          />
          <div class="small text-secondary font-weight-bold">
            This is what you expect to receive as $ at the point right before the execution of this Javascrip Agent
          </div>
        </b-collapse>
      </div>
    </b-collapse>
  </div>

  <div class="run-test-debugger-container">
    <b-collapse :visible="debugMode">
        <div align="right" class="pb-50">  
          <b-button class="" variant="success" @click="runDebugTest()">
            <span class="text-black">
              <b-icon icon="play-fill"/> 
              Run Test
            </span>
          </b-button>
        </div>

        <b-collapse :visible="!!debugData.testResult.data" appear :key="'result-collapse-'+debugData.resultCollapseKey">
          
          <div class="test-result-container">
            <div class="d-flex justify-content-between align-items-end">
            
              <h5 class="text-black font-weight-bolder">Test Result:</h5>

              <b-button variant="none" class="p-25" @click="debugData.testResult={data:undefined, status:undefined, logs: undefined,}">
                <b-icon icon="x" variant="black"/>
              </b-button>
            </div>

            <template v-if="debugData.testResult.status == 'success'">
            <div align="center" class="text-success small font-weight-bolder bg-dark px-25 rounded mb-25">SUCCESSFULL EXECUTION</div>
            </template>

            <div v-if="debugData.testResult.logs && debugData.testResult.logs.length > 0" class="font-weight-bold border rounded">
              <div align="center" class="result-title"> 
                <b-icon icon="layout-text-sidebar-reverse"/>
                Logs:
              </div>
              
              <div v-for="(log, idx) in debugData.testResult.logs" class="px-50 my-25 word-break-all">
                <b-icon icon="arrow-right-short" scale="1" variant="success" class="mr-25" shift-v="-2"/>
                <span class="small">{{log}}</span>
              </div>

            </div>


            <div class="mt-50 border rounded">
              <template v-if="debugData.testResult.status == 'success'">
                <div align="center" class="font-weight-bolder small result-title">$ After Execution:</div>
                <pre class="payload-result">{{debugData.testResult.data}}</pre>
              </template>
              <template v-if="debugData.testResult.status == 'error'">
                <div class="result-title bg-danger text-light" align="center">ERROR</div>
                <div class="payload-result">{{debugData.testResult.data}}</div>
              </template>
            </div>
          </div>

        </b-collapse>
    </b-collapse>
  </div>
  
    <div class="flex-1 h-100">  
      <codemirror
        language="javascript"
        height="100%"
        class="js-codemirror-editor h-100"
        v-model="agent.register_1.value"
        minimap_on
        @MonacoCtrlS="saveWithoutClose()"
        @CtrlS="saveWithoutClose()"
      />
    </div>
    
  <div class="py-75 small text-secondary text-left mx-2">
    <span class="mx-50">
      Javascript Version: 
      <b-link class="font-weight-bolder text-secondary" href="https://262.ecma-international.org/5.1/" target="_blank">ECMAScript 5.1</b-link>
    </span>
  </div>
</div> 
</template>

<script>
import { 
  BLink, 
  BSkeleton, 
  BFormCheckbox, 
  BButton, 
  BContainer, 
  BCol, 
  BRow, 
  BSidebar, 
  BIcon,
  BCollapse,
 } from "bootstrap-vue";
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import Monaco from '@/layouts/components/editor/monaco.vue';
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import AgentsMixin from '../AgentsMixin'
import codemirror from '@/layouts/components/editor/codeMirror.vue'

export default {
  mixins: [AgentsMixin],
  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
    PrismEditor,
    Monaco,
    BCollapse,
    JsonEditor,
    codemirror
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: NormalizedAgent,
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    width: {
      type: String,
      default: "70%"
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    isDebugMode: {
      type: Boolean,
      default: false
    },
    variables:{
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      actionID: 18,
      i18nKey: 'execute_javascript',
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ``
        },
        execution_order: 1,
        fatal_on_fail: true,
      },

      uuidMap: {},

      variant_map: null,

      isSaving: false,
      debugMode: false,
      debugData:{
        showInitialState: true,
        initialState: {},
        testResult: {
          data: undefined,
          status: undefined,
          logs: undefined,
        },
        resultCollapseKey: 1
      }
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    toSaveRegs() {
      return {
        register_1: true,
        register_2: false,
        register_destiny: false,
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      if (this.isDebugMode){
        this.debugMode = true
      }
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },      
    highlighter(code) {        
      return highlight(code, languages.js);
    },
    beforeSave(){
      if (this.debugMode && !this.isDebugMode){ // if it's manually set to Debug Mode
        console.log('ooh ye')
      }
      return 
    },
    saveWithoutClose(){
      document.activeElement.blur();
      this.$emit("save")
      // DefaultAgent.save(this, false)
    },
    runDebugTest(){
        let script = structuredClone(this.agent.register_1.value);
        let $ = structuredClone(this.debugData.initialState);
        let logs = []
        let code = `
          try{
            let $ = ${JSON.stringify($)};
            ${(script)}
            return [$, 'success'];
          }
          catch(err){
            return [err.toString() , 'error']
          } `;

        let result;
        (function(code){
          console.log = function(r){
            logs.push(r)
          }
          try {
            let r = (Function(code)())
            result = {
              data: r[0],
              status: r[1]
            }
          }
          catch(err){
            result = {
              data: err.toString(),
              status: 'error'
            }
          }
        })(code);

        result.logs = logs
        if (result.status == 'success'){
          result.data = JSON.stringify((result.data), null, 2)
        }

        this.debugData.testResult = result        

        this.debugData.resultCollapseKey = this.debugData.resultCollapseKey + 1
    }
  },
};
</script>


<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}


.header{
  background-color: #151925;
}

.js-codemirror-editor{
  border-radius: 1px;
  border: none !important;
  margin: 0;
  height: 100% !important;
  outline: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.debugger-container{
  position: absolute;
  bottom: 30px;
  left: 0px;
  padding: 10px;
  z-index: 1;
  
  .debug-container-inner{
    background-color: #353535;
    padding: 3px 10px;
    border-radius: 5px;
    width: 400px;
  }
}

  .run-test-debugger-container{
    position: absolute;
    right: 0px;
    bottom: 20px;
    padding: 10px;
    margin: 0;
    z-index: 1;
  }

  .test-result-container{

    border: 2px dotted rgba(44, 44, 44, 0.25);
    background-color: rgb(110, 110, 110);
    border-radius: 5px;
    padding: 5px 10px;
    width: 500px;
    color: black;
    max-height: 500px;
    overflow-y: auto;
  }

  .payload-result{
    padding: 0;
    margin: 0;
    overflow: auto;
    color: var(--light);
    background-color: rgba(0, 0, 0, 0.2) !important;
    border-radius: 0 0 5px 5px;
    padding: 5px 10px;
    font-weight: 500;
    font-family: 'Courier New', Courier, monospace;
    &.pre{

    }
  }

  .payload-result-title{
    background-color: rgba(0, 0, 0, 0.2);
    width: fit-content;
    padding: 0 4px;
    display: block;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  }

.result-title{
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  letter-spacing: 1px;
  font-weight: bolder;
}

.word-break-all{
  word-break: break-all;
}
</style>
