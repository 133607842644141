import {tags as t} from '@lezer/highlight';
export default class codeMirrorThemes {
  constructor() {
    this.items = {
      "ambience": {
        label: 'ambience (default)',
        variant: 'dark',
        settings: {
          background: '#1b2030',
          caret: '#f6f6f6',
          selection: '#000',
          gutterBackground: '#1f2536',
          gutterForeground: '#d0d2d6',
          lineHighlight: '#191d2c',
          
          selection: '#f6f6f6',
          lineHighlight: '#00000025',
        },
        styles: [
          {
            tag: t.comment,
            color: '#515561',
          },
          {
            tag: [t.string, t.regexp],
            color: '#00cfe8',
          },
          {
            tag: t.number,
            color: '#ff9f43',
          },
          {
            tag: [t.bool, t.null],
            color: '#ffe712',
          },
          {
            tag: [t.punctuation, t.derefOperator],
            color: '#07E6A1',
          },
          {
            tag: t.keyword,
            color: '#4e77ff',
          },
          {
            tag: t.definitionKeyword,
            color: '#4e77ff',
          },
          {
            tag: t.moduleKeyword,
            color: '#4e77ff',
          },
          {
            tag: t.operator,
            color: '#959ba1',
          },
          {
            tag: [t.variableName, t.self],
            color: '#d0d2d6',
          },
          {
            tag: t.operatorKeyword,
            color: '#ea5455',
          },
          {
            tag: t.controlKeyword,
            color: '#d0d2d6',
          },
          {
            tag: t.className,
            color: '#ea5455',
          },
          {
            tag: [t.function(t.propertyName), t.propertyName],
            color: '#BE57FF',
          },
          {
            tag: t.tagName,
            color: '#ea5455',
          },
          {
            tag: t.modifier,
            color: '#ea5455',
          },
          {
            tag: [t.squareBracket, t.attributeName],
            color: '#7367f0',
          },
        ],
      },

      "amy": {
        variant: 'dark',
        settings: {
          background: '#200020',
          foreground: '#D0D0FF',
          caret: '#7070FF',
          selection: '#80000080',
          gutterBackground: '#200020',
          gutterForeground: '#C080C0',
          lineHighlight: '#80000040',
        },
        styles: [
          {
            tag: t.comment,
            color: '#404080',
          },
          {
            tag: [t.string, t.regexp],
            color: '#999999',
          },
          {
            tag: t.number,
            color: '#7090B0',
          },
          {
            tag: [t.bool, t.null],
            color: '#8080A0',
          },
          {
            tag: [t.punctuation, t.derefOperator],
            color: '#805080',
          },
          {
            tag: t.keyword,
            color: '#60B0FF',
          },
          {
            tag: t.definitionKeyword,
            color: '#B0FFF0',
          },
          {
            tag: t.moduleKeyword,
            color: '#60B0FF',
          },
          {
            tag: t.operator,
            color: '#A0A0FF',
          },
          {
            tag: [t.variableName, t.self],
            color: '#008080',
          },
          {
            tag: t.operatorKeyword,
            color: '#A0A0FF',
          },
          {
            tag: t.controlKeyword,
            color: '#80A0FF',
          },
          {
            tag: t.className,
            color: '#70E080',
          },
          {
            tag: [t.function(t.propertyName), t.propertyName],
            color: '#50A0A0',
          },
          {
            tag: t.tagName,
            color: '#009090',
          },
          {
            tag: t.modifier,
            color: '#B0FFF0',
          },
          {
            tag: [t.squareBracket, t.attributeName],
            color: '#D0D0FF',
          },
        ],
      },
      "ayu-lights": {
        variant: 'light',
        settings: {
          background: '#fcfcfc',
          foreground: '#5c6166',
          caret: '#ffaa33',
          selection: '#036dd626',
          gutterBackground: '#fcfcfc',
          gutterForeground: '#8a919966',
          lineHighlight: '#8a91991a',
        },
        styles: [
          {
            tag: t.comment,
            color: '#787b8099',
          },
          {
            tag: t.string,
            color: '#86b300',
          },
          {
            tag: t.regexp,
            color: '#4cbf99',
          },
          {
            tag: [t.number, t.bool, t.null],
            color: '#ffaa33',
          },
          {
            tag: t.variableName,
            color: '#5c6166',
          },
          {
            tag: [t.definitionKeyword, t.modifier],
            color: '#fa8d3e',
          },
          {
            tag: [t.keyword, t.special(t.brace)],
            color: '#fa8d3e',
          },
          {
            tag: t.operator,
            color: '#ed9366',
          },
          {
            tag: t.separator,
            color: '#5c6166b3',
          },
          {
            tag: t.punctuation,
            color: '#5c6166',
          },
          {
            tag: [t.definition(t.propertyName), t.function(t.variableName)],
            color: '#f2ae49',
          },
          {
            tag: [t.className, t.definition(t.typeName)],
            color: '#22a4e6',
          },
          {
            tag: [t.tagName, t.typeName, t.self, t.labelName],
            color: '#55b4d4',
          },
          {
            tag: t.angleBracket,
            color: '#55b4d480',
          },
          {
            tag: t.attributeName,
            color: '#f2ae49',
          },
        ],
      },
      "barf":{
        variant: 'dark',
        settings: {
          background: '#15191EFA',
          foreground: '#EEF2F7',
          caret: '#C4C4C4',
          selection: '#90B2D557',
          gutterBackground: '#15191EFA',
          gutterForeground: '#aaaaaa95',
          lineHighlight: '#57575712',
        },
        styles: [
          {
            tag: t.comment,
            color: '#6E6E6E',
          },
          {
            tag: [t.string, t.regexp, t.special(t.brace)],
            color: '#5C81B3',
          },
          {
            tag: t.number,
            color: '#C1E1B8',
          },
          {
            tag: t.bool,
            color: '#53667D',
          },
          {
            tag: [t.definitionKeyword, t.modifier, t.function(t.propertyName)],
            color: '#A3D295',
            fontWeight: 'bold',
          },
          {
            tag: [t.keyword, t.moduleKeyword, t.operatorKeyword, t.operator],
            color: '#697A8E',
            fontWeight: 'bold',
          },
          {
            tag: [t.variableName, t.attributeName],
            color: '#708E67',
          },
          {
            tag: [
              t.function(t.variableName),
              t.definition(t.propertyName),
              t.derefOperator,
            ],
            color: '#fff',
          },
          {
            tag: t.tagName,
            color: '#A3D295',
          },
        ],
      },
      "bespin":{
        variant: 'dark',
        settings: {
          background: '#2e241d',
          foreground: '#BAAE9E',
          caret: '#A7A7A7',
          selection: '#DDF0FF33',
          gutterBackground: '#28211C',
          gutterForeground: '#BAAE9E90',
          lineHighlight: '#FFFFFF08',
        },
        styles: [
          {
            tag: t.comment,
            color: '#666666',
          },
          {
            tag: [t.string, t.special(t.brace)],
            color: '#54BE0D',
          },
          {
            tag: t.regexp,
            color: '#E9C062',
          },
          {
            tag: t.number,
            color: '#CF6A4C',
          },
          {
            tag: [t.keyword, t.operator],
            color: '#5EA6EA',
          },
          {
            tag: t.variableName,
            color: '#7587A6',
          },
          {
            tag: [t.definitionKeyword, t.modifier],
            color: '#F9EE98',
          },
          {
            tag: [t.propertyName, t.function(t.variableName)],
            color: '#937121',
          },
          {
            tag: [t.typeName, t.angleBracket, t.tagName],
            color: '#9B859D',
          },
        ],
      },
      "birds-of-paradise":{
        variant: 'dark',
        settings: {
          background: '#3b2627',
          foreground: '#E6E1C4',
          caret: '#E6E1C4',
          selection: '#16120E',
          gutterBackground: '#3b2627',
          gutterForeground: '#E6E1C490',
          lineHighlight: '#1F1611',
        },
        styles: [
          {
            tag: t.comment,
            color: '#6B4E32',
          },
          {
            tag: [t.keyword, t.operator, t.derefOperator],
            color: '#EF5D32',
          },
          {
            tag: t.className,
            color: '#EFAC32',
            fontWeight: 'bold',
          },
          {
            tag: [
              t.typeName,
              t.propertyName,
              t.function(t.variableName),
              t.definition(t.variableName),
            ],
            color: '#EFAC32',
          },
          {
            tag: t.definition(t.typeName),
            color: '#EFAC32',
            fontWeight: 'bold',
          },
          {
            tag: t.labelName,
            color: '#EFAC32',
            fontWeight: 'bold',
          },
          {
            tag: [t.number, t.bool],
            color: '#6C99BB',
          },
          {
            tag: [t.variableName, t.self],
            color: '#7DAF9C',
          },
          {
            tag: [t.string, t.special(t.brace), t.regexp],
            color: '#D9D762',
          },
          {
            tag: [t.angleBracket, t.tagName, t.attributeName],
            color: '#EFCB43',
          },
        ],
      },
      "boys-and-girls":{
        variant: 'dark',
        settings: {
          background: '#000205',
          foreground: '#FFFFFF',
          caret: '#E60065',
          selection: '#E60C6559',
          gutterBackground: '#000205',
          gutterForeground: '#ffffff90',
          lineHighlight: '#4DD7FC1A',
        },
        styles: [
          {
            tag: t.comment,
            color: '#404040',
          },
          {
            tag: [t.string, t.special(t.brace), t.regexp],
            color: '#00D8FF',
          },
          {
            tag: t.number,
            color: '#E62286',
          },
          {
            tag: [t.variableName, t.attributeName, t.self],
            color: '#E62286',
            fontWeight: 'bold',
          },
          {
            tag: t.function(t.variableName),
            color: '#fff',
            fontWeight: 'bold',
          },
        ],
      },
      "clouds":{
        variant: 'light',
        settings: {
          background: '#fff',
          foreground: '#000',
          caret: '#000',
          selection: '#BDD5FC',
          gutterBackground: '#fff',
          gutterForeground: '#00000070',
          lineHighlight: '#FFFBD1',
        },
        styles: [
          {
            tag: t.comment,
            color: '#BCC8BA',
          },
          {
            tag: [t.string, t.special(t.brace), t.regexp],
            color: '#5D90CD',
          },
          {
            tag: [t.number, t.bool, t.null],
            color: '#46A609',
          },
          {
            tag: t.keyword,
            color: '#AF956F',
          },
          {
            tag: [t.definitionKeyword, t.modifier],
            color: '#C52727',
          },
          {
            tag: [t.angleBracket, t.tagName, t.attributeName],
            color: '#606060',
          },
          {
            tag: t.self,
            color: '#000',
          },
        ],
      },
      "cobalt":{
        variant: 'dark',
        settings: {
          background: '#00254b',
          foreground: '#FFFFFF',
          caret: '#FFFFFF',
          selection: '#B36539BF',
          gutterBackground: '#00254b',
          gutterForeground: '#FFFFFF70',
          lineHighlight: '#00000059',
        },
        styles: [
          {
            tag: t.comment,
            color: '#0088FF',
          },
          {
            tag: t.string,
            color: '#3AD900',
          },
          {
            tag: t.regexp,
            color: '#80FFC2',
          },
          {
            tag: [t.number, t.bool, t.null],
            color: '#FF628C',
          },
          {
            tag: [t.definitionKeyword, t.modifier],
            color: '#FFEE80',
          },
          {
            tag: t.variableName,
            color: '#CCCCCC',
          },
          {
            tag: t.self,
            color: '#FF80E1',
          },
          {
            tag: [
              t.className,
              t.definition(t.propertyName),
              t.function(t.variableName),
              t.definition(t.typeName),
              t.labelName,
            ],
            color: '#FFDD00',
          },
          {
            tag: [t.keyword, t.operator],
            color: '#FF9D00',
          },
          {
            tag: [t.propertyName, t.typeName],
            color: '#80FFBB',
          },
          {
            tag: t.special(t.brace),
            color: '#EDEF7D',
          },
          {
            tag: t.attributeName,
            color: '#9EFFFF',
          },
          {
            tag: t.derefOperator,
            color: '#fff',
          },
        ],
      },
      "cool-glow":{
        variant: 'dark',
        settings: {
          background: '#060521',
          foreground: '#E0E0E0',
          caret: '#FFFFFFA6',
          selection: '#122BBB',
          gutterBackground: '#060521',
          gutterForeground: '#E0E0E090',
          lineHighlight: '#FFFFFF0F',
        },
        styles: [
          {
            tag: t.comment,
            color: '#AEAEAE',
          },
          {
            tag: [t.string, t.special(t.brace), t.regexp],
            color: '#8DFF8E',
          },
          {
            tag: [
              t.className,
              t.definition(t.propertyName),
              t.function(t.variableName),
              t.function(t.definition(t.variableName)),
              t.definition(t.typeName),
            ],
            color: '#A3EBFF',
          },
          {
            tag: [t.number, t.bool, t.null],
            color: '#62E9BD',
          },
          {
            tag: [t.keyword, t.operator],
            color: '#2BF1DC',
          },
          {
            tag: [t.definitionKeyword, t.modifier],
            color: '#F8FBB1',
          },
          {
            tag: [t.variableName, t.self],
            color: '#B683CA',
          },
          {
            tag: [t.angleBracket, t.tagName, t.typeName, t.propertyName],
            color: '#60A4F1',
          },
          {
            tag: t.derefOperator,
            color: '#E0E0E0',
          },
          {
            tag: t.attributeName,
            color: '#7BACCA',
          },
        ],
      },
      "dracula":{
        variant: 'dark',
        settings: {
          background: '#2d2f3f',
          foreground: '#f8f8f2',
          caret: '#f8f8f0',
          selection: '#44475a',
          gutterBackground: '#282a36',
          gutterForeground: 'rgb(144, 145, 148)',
          lineHighlight: '#44475a',
        },
        styles: [
          {
            tag: t.comment,
            color: '#6272a4',
          },
          {
            tag: [t.string, t.special(t.brace)],
            color: '#f1fa8c',
          },
          {
            tag: [t.number, t.self, t.bool, t.null],
            color: '#bd93f9',
          },
          {
            tag: [t.keyword, t.operator],
            color: '#ff79c6',
          },
          {
            tag: [t.definitionKeyword, t.typeName],
            color: '#8be9fd',
          },
          {
            tag: t.definition(t.typeName),
            color: '#f8f8f2',
          },
          {
            tag: [
              t.className,
              t.definition(t.propertyName),
              t.function(t.variableName),
              t.attributeName,
            ],
            color: '#50fa7b',
          },
        ],
      },
      "espresso":{
        variant: 'light',
        settings: {
          background: '#FFFFFF',
          foreground: '#000000',
          caret: '#000000',
          selection: '#80C7FF',
          gutterBackground: '#FFFFFF',
          gutterForeground: '#00000070',
          lineHighlight: '#C1E2F8',
        },
        styles: [
          {
            tag: t.comment,
            color: '#AAAAAA',
          },
          {
            tag: [t.keyword, t.operator, t.typeName, t.tagName, t.propertyName],
            color: '#2F6F9F',
            fontWeight: 'bold',
          },
          {
            tag: [t.attributeName, t.definition(t.propertyName)],
            color: '#4F9FD0',
          },
          {
            tag: [t.className, t.string, t.special(t.brace)],
            color: '#CF4F5F',
          },
          {
            tag: t.number,
            color: '#CF4F5F',
            fontWeight: 'bold',
          },
          {
            tag: t.variableName,
            fontWeight: 'bold',
          },
        ],
      },
      "noctis-lilac":{
        variant: 'light',
        settings: {
          background: '#f2f1f8',
          foreground: '#0c006b',
          caret: '#5c49e9',
          selection: '#d5d1f2',
          gutterBackground: '#f2f1f8',
          gutterForeground: '#0c006b70',
          lineHighlight: '#e1def3',
        },
        styles: [
          {
            tag: t.comment,
            color: '#9995b7',
          },
          {
            tag: t.keyword,
            color: '#ff5792',
            fontWeight: 'bold',
          },
          {
            tag: [t.definitionKeyword, t.modifier],
            color: '#ff5792',
          },
          {
            tag: [t.className, t.tagName, t.definition(t.typeName)],
            color: '#0094f0',
          },
          {
            tag: [t.number, t.bool, t.null, t.special(t.brace)],
            color: '#5842ff',
          },
          {
            tag: [t.definition(t.propertyName), t.function(t.variableName)],
            color: '#0095a8',
          },
          {
            tag: t.typeName,
            color: '#b3694d',
          },
          {
            tag: [t.propertyName, t.variableName],
            color: '#fa8900',
          },
          {
            tag: t.operator,
            color: '#ff5792',
          },
          {
            tag: t.self,
            color: '#e64100',
          },
          {
            tag: [t.string, t.regexp],
            color: '#00b368',
          },
          {
            tag: [t.paren, t.bracket],
            color: '#0431fa',
          },
          {
            tag: t.labelName,
            color: '#00bdd6',
          },
          {
            tag: t.attributeName,
            color: '#e64100',
          },
          {
            tag: t.angleBracket,
            color: '#9995b7',
          },
        ],
      },
      "rose-pine-dawn":{
        variant: 'light',
        settings: {
          background: '#faf4ed',
          foreground: '#575279',
          caret: '#575279',
          selection: '#6e6a8614',
          gutterBackground: '#faf4ed',
          gutterForeground: '#57527970',
          lineHighlight: '#6e6a860d',
        },
        styles: [
          {
            tag: t.comment,
            color: '#9893a5',
          },
          {
            tag: [t.bool, t.null],
            color: '#286983',
          },
          {
            tag: t.number,
            color: '#d7827e',
          },
          {
            tag: t.className,
            color: '#d7827e',
          },
          {
            tag: [t.angleBracket, t.tagName, t.typeName],
            color: '#56949f',
          },
          {
            tag: t.attributeName,
            color: '#907aa9',
          },
          {
            tag: t.punctuation,
            color: '#797593',
          },
          {
            tag: [t.keyword, t.modifier],
            color: '#286983',
          },
          {
            tag: [t.string, t.regexp],
            color: '#ea9d34',
          },
          {
            tag: t.variableName,
            color: '#d7827e',
          },
        ],
      },
      "smoothy":{
        variant: 'light',
        settings: {
          background: '#FFFFFF',
          foreground: '#000000',
          caret: '#000000',
          selection: '#FFFD0054',
          gutterBackground: '#FFFFFF',
          gutterForeground: '#00000070',
          lineHighlight: '#00000008',
        },
        styles: [
          {
            tag: t.comment,
            color: '#CFCFCF',
          },
          {
            tag: [t.number, t.bool, t.null],
            color: '#E66C29',
          },
          {
            tag: [
              t.className,
              t.definition(t.propertyName),
              t.function(t.variableName),
              t.labelName,
              t.definition(t.typeName),
            ],
            color: '#2EB43B',
          },
          {
            tag: t.keyword,
            color: '#D8B229',
          },
          {
            tag: t.operator,
            color: '#4EA44E',
            fontWeight: 'bold',
          },
          {
            tag: [t.definitionKeyword, t.modifier],
            color: '#925A47',
          },
          {
            tag: t.string,
            color: '#704D3D',
          },
          {
            tag: t.typeName,
            color: '#2F8996',
          },
          {
            tag: [t.variableName, t.propertyName],
            color: '#77ACB0',
          },
          {
            tag: t.self,
            color: '#77ACB0',
            fontWeight: 'bold',
          },
          {
            tag: t.regexp,
            color: '#E3965E',
          },
          {
            tag: [t.tagName, t.angleBracket],
            color: '#BAA827',
          },
          {
            tag: t.attributeName,
            color: '#B06520',
          },
          {
            tag: t.derefOperator,
            color: '#000',
          },
        ],
      },
      "solarized-lights":{
        variant: 'light',
        settings: {
          background: '#fef7e5',
          foreground: '#586E75',
          caret: '#000000',
          selection: '#073642',
          gutterBackground: '#fef7e5',
          gutterForeground: '#586E7580',
          lineHighlight: '#EEE8D5',
        },
        styles: [
          {
            tag: t.comment,
            color: '#93A1A1',
          },
          {
            tag: t.string,
            color: '#2AA198',
          },
          {
            tag: t.regexp,
            color: '#D30102',
          },
          {
            tag: t.number,
            color: '#D33682',
          },
          {
            tag: t.variableName,
            color: '#268BD2',
          },
          {
            tag: [t.keyword, t.operator, t.punctuation],
            color: '#859900',
          },
          {
            tag: [t.definitionKeyword, t.modifier],
            color: '#073642',
            fontWeight: 'bold',
          },
          {
            tag: [t.className, t.self, t.definition(t.propertyName)],
            color: '#268BD2',
          },
          {
            tag: t.function(t.variableName),
            color: '#268BD2',
          },
          {
            tag: [t.bool, t.null],
            color: '#B58900',
          },
          {
            tag: t.tagName,
            color: '#268BD2',
            fontWeight: 'bold',
          },
          {
            tag: t.angleBracket,
            color: '#93A1A1',
          },
          {
            tag: t.attributeName,
            color: '#93A1A1',
          },
          {
            tag: t.typeName,
            color: '#859900',
          },
        ],
      },
      "tomorrow":{
        variant: 'light',
        settings: {
          background: '#FFFFFF',
          foreground: '#4D4D4C',
          caret: '#AEAFAD',
          selection: '#D6D6D6',
          gutterBackground: '#FFFFFF',
          gutterForeground: '#4D4D4C80',
          lineHighlight: '#EFEFEF',
        },
        styles: [
          {
            tag: t.comment,
            color: '#8E908C',
          },
          {
            tag: [t.variableName, t.self, t.propertyName, t.attributeName, t.regexp],
            color: '#C82829',
          },
          {
            tag: [t.number, t.bool, t.null],
            color: '#F5871F',
          },
          {
            tag: [t.className, t.typeName, t.definition(t.typeName)],
            color: '#C99E00',
          },
          {
            tag: [t.string, t.special(t.brace)],
            color: '#718C00',
          },
          {
            tag: t.operator,
            color: '#3E999F',
          },
          {
            tag: [t.definition(t.propertyName), t.function(t.variableName)],
            color: '#4271AE',
          },
          {
            tag: t.keyword,
            color: '#8959A8',
          },
          {
            tag: t.derefOperator,
            color: '#4D4D4C',
          },
        ],
      },

    };
  }
}